<template>
  <div class="setting privacy world">
    <div class="title backgroundWhite marB10">
      <h1 class="h1">隐私设置</h1>
    </div>
    <!-- <div class="gray"></div> -->
    <div class="setting_content backgroundWhite gray">
      <ul class="top flex">
        <li class="left">
          <h4 class="marR10">简历可见设置</h4>
          <p class="color66 marT20">隐藏简历后，您将不会被推荐给企业HR，除非您主动简历联系，否则企业无法查看您的简历，也无法与您沟通</p>
        </li>
        <li class="right text_R">
          <p class="block_inline marT20">
            <span class="paddR10">{{seeStatus?'已开放简历':'已隐藏简历'}}</span>
          </p>
          <el-switch
          aria-label="是否隐藏简历"
            v-model="seeStatus"
            active-color="#00924c"
            inactive-color="#999999"
            @change="updateIsSee"
          ></el-switch>
        </li>
      </ul>
      <ul class="top">
        <li class="left">
          <h4 class="marR10">屏蔽公司</h4>
          <p class="shield marT10">输入想屏蔽的公司，支持公司全称、简称</p>
          <p class="shield">屏蔽后，此公司下的所有HR将无法通过人才推荐，搜索等入口看见你</p>
          <p class="shield">屏蔽后，你将不会在各个推荐入口看见此公司职位，但仍然可以在该公司主页下查看其所有职位</p>
        </li>
      </ul>
      <ul class="top flex">
        <li class="left">
          <h4 class="marR10">已屏蔽企业</h4>
          <div
            class="marT20 coms"
            @click="deleteShield(item.id)"
            v-for="item in shieldList"
            :key="item.id"
          >
            <p>{{item.companyInfo.companyName}}</p>
            <span class>
              <i class="el-icon-delete cursorP"></i>
            </span>
          </div>
        </li>
        <li class="right" v-if="shieldList">
          <el-button type="primary" size="mini" @click="add()">添加要屏蔽的公司</el-button>
        </li>
      </ul>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="isHide"
      width="600px"
      v-if="isHide"
      :before-close="handleClose"
    >
      <alert-shield
        @close="close"
        @queryUnShield="queryUnShield"
        @addShield="addShield"
        :comList="comList"
      ></alert-shield>
    </el-dialog>
  </div>
</template>

<script>
import alertShield from "@/components/center/alertShield";
export default {
  name: "settingPrivacy",
  components: {
    alertShield,
  },
  data() {
    return {
      title: "公司搜索",
      search: "",
      count: "0",
      width: "width:590px",
      memberId: "",
      isHide: false,
      seeStatus: "",
      changeStatus: [],
      comList: [],
      shieldList: [],
      params: {
        pageNum: 1,
        pageSize: 7,
        companyName: "",
      },
      status: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 添加屏蔽
    add() {
      console.log(this.shieldList);
      if (this.shieldList) {
        if (this.shieldList.length < 5) {
          console.log(this.shieldList);
          this.isHide = true;
        } else {
          this.$message.warning("最多添加五家企业！");
          this.isHide = false;
        }
      }
    }, // 查询已屏蔽
    async queryShield() {
      try {
        let res = await this.$api.queryShield(this.params);
        console.log("查询已屏蔽", res);
        if (res.data.success) {
          this.shieldList = res.data.data;
          console.log(this.shieldList);
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {}
    },
    async queryIsSee() {
      try {
        let res = await this.$api.queryIsSee();
        if (res.data.success) {
          this.seeStatus = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateIsSee() {
      try {
        let res = await this.$api.updateIsSee();
        if (res.data.success) {
          this.changeStatus = res.data.data;
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async queryUnShield(value) {
      try {
        if (!value) {
          this.$message.warning("请输入要屏蔽的公司名称!");
          return;
        }
        this.params = {
          pageNum: 1,
          pageSize: 7,
          companyName: value,
        };
        let res = await this.$api.queryUnShield(this.params);
        console.log("查询即将屏蔽公司", res.data.success);
        if (res.data.success) {
          this.comList = res.data.data.records;
        }
      } catch (error) {}
    },

    // 添加要屏蔽公司
    async addShield(id) {
      console.log(id);
      try {
        let ids = id.join(",");
        console.log(ids);
        let parama = {
          comId: ids,
        };
        let res = await this.$api.addShield(parama);
        if (res.data.success) {
          this.$message.success(res.data.msg);
          this.isHide = false;
          this.queryShield();
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {}
    },
    // 删除已经屏蔽公司
    deleteShield(id) {
      this.$confirm("确定取消对当前企业的屏蔽吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let res = await this.$api.deleteShield({ id: id });
            console.log("删除已经屏蔽公司", res);
            if (res.data.success) {
              this.$message({
                type: "success",
                message: res.data.msg,
              });
              this.queryShield();
            }
          } catch (error) {}
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClose() {
      this.isHide = false;
    },

    delate() {},
    opens() {
      if (this.count == 3) {
        this.$message({
          message: "最多屏蔽三家企业",
          center: true,
        });
      } else {
        this.isHide = true;
      }
    },
    // 关闭当前弹窗
    close(val) {
      this.isHide = val;
    },
  },
  created() {
    this.queryIsSee();
    this.queryShield();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/setting.less";

.setting_content {
  .top {
    border-bottom: 1px solid #ededed;
    .left {
      h4 {
        font-size: 16px;
      }
    }
    .right {
      span {
        color: #999999;
      }
    }
  }
  .content {
    .addCompany {
      .left {
        flex: 1;
        h4 {
          font-size: 16px;
        }
      }
      .right {
        flex: 1;
        button {
          font-size: 16px;
        }
      }
    }
    ul.ul_content {
      li {
        padding: 5px 0;
        font-size: 12px;
        .left,
        .right {
          flex: 1;
          button {
            font-size: 12px;
          }
          font-size: 12px;
        }
      }
    }
    .prompt {
      margin-top: 100px;
      background: #f2f2f2;
      h1 {
        padding: 20px;
      }
      ul {
        padding: 0 20px 20px;
        li {
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }
  .coms {
    display: flex;
    width: 60%;

    .el-icon-delete {
      padding: 0 10px;
    }
  }
}
/deep/
  .search_input
  .el-input
  .el-input-group
  .el-input-group--append
  .el-input-group--prepend {
  border-color: 1px solid #00924c;
}
.search_input {
  margin-top: 20px;
  width: 80%;
}
/deep/ .el-dialog__body {
  padding: 15px 20px;
}
</style>
