<template>
  <div class="world backgroundWhite">
    <h4>注意哦！最多可同时屏蔽五家企业！一次只可屏蔽一家企业！</h4>
    <div class="content child_content">
      <div class="form_input paddT20">
        <el-input placeholder="请输入企业全称或者简称" v-model="value" class="width100">
          <template slot="append">
            <el-button
              class="bs_btn_oo color255"
              icon="el-icon-search"
              @click="queryUnShield"
              type="primary"
              :disabled="disabled"
              id="sendTime"
            >搜索</el-button>
          </template>
        </el-input>
      </div>
      <div class="child_box">
        <div class="prompt color99 paddT10 paddB10">
          与
          <span style="font-weight: bold">{{value}}</span>相关的公司，共
          <span>{{checkedList.length}}</span>个
        </div>
        <el-checkbox-group v-model="checkedList" :max="1" @change="handlecheckedListChange">
          <el-checkbox v-for="item in comList" :label="item.id" :key="item.id">{{item.companyName}}</el-checkbox>
        </el-checkbox-group>
        <div></div>

        <div class="h160"></div>
        <div class="check">
          <div class="left"></div>
          <div class="right text_R">
            <el-button type="primary" size="mini" @click="addShield">屏蔽所选公司</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "alertShield",
  props: ["comList"],
  data() {
    return {
      disabled: false,
      value: "",
      valueCopy: "",
      checkAll: false,
      checkedList: [],
      total: "",
      count: "0",
      isIndeterminate: true,
      params: {
        pageNum: 1,
        pageSize: 7,
        companyName: "",
      },
    };
  },
  methods: {
    // 查询未屏蔽
    queryUnShield(value) {
      this.$emit("queryUnShield", this.value);
    },

    // 添加要屏蔽公司id
    addShield(id) {
      this.$emit("addShield", this.checkedList);
    },

    close() {
      this.$emit("close", false);
    },
    handlecheckedListChange(checkedList) {
      console.log("选中企业", checkedList);
      let that = this;
      if (that.comList) {
        let checkedCount = checkedList.length;
        that.checkAll = checkedCount === that.comList.length;
        that.isIndeterminate =
          checkedCount > 0 && checkedCount < that.comList.length;
      }
    },
  },
  created() {
    if (this.comList) {
      this.length = this.comList.length;
    }
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/setting.less";
h3 {
  margin-bottom: 20px;
}
.el-checkbox {
  width: 100%;
}
.setting .content {
  padding: 0px 20px 20px !important;
}
.child_content {
  .content {
    padding: 0px 20px !important;
    .form_input {
      .el-input {
        .el-input-group__append {
          button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
    .child_box {
      .content_ul {
        li {
          padding: 35px 0;
          border-bottom: 1px solid #ededed;
        }
      }
    }
  }
  .check {
    display: flex;
    justify-content: space-between;
  }
}
/deep/ .el-input-group__append,
.el-input-group__prepend {
  background-color: #00924c;
  color: #ffffff;
}
</style>
